.grid-item-block {
    /*border: solid 1px #999999;*/
    /*background: #f5f5f5;*/
    text-align: center;
    color: #999999;
    padding: 0.24rem;
    height: 100%;
}

.grid-item-block-img {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
}

.grid-item-block-text {
    color: #697b8c;
    width: 100%;
    margin-top: 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.element {
    position: fixed;
    bottom: 0;
    width: 100%; /* 或者你想要的宽度 */
}

.swiper {
    height: 120px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
}